import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';

const initialState = {
	data: [],
	loading: false,
	error: null
};

export const getBusStopThunk: any = createAsyncThunk('busStops', async (state: any, {rejectWithValue}) => {
	let data;
	try {
		data = await SERVICES.BUS_STOPS.getBusStop(state);
	} catch (err) {
		return rejectWithValue(err);
	}
	return data;
});

const busStopSlice = createSlice({
	name: 'busStops',
	initialState,
	reducers: {
		emptyArray: state => {
			state.data = [];
		}
	},
	extraReducers: builder => {
		builder.addCase(getBusStopThunk.pending, state => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getBusStopThunk.fulfilled, (state, action) => {
			state.data = action.payload.data.items;
			state.loading = false;
		});
		builder.addCase(getBusStopThunk.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload || action.error.message;
		});
	}
});
export const {emptyArray} = busStopSlice.actions;
export default busStopSlice.reducer;
