import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';

let initialState = {
	faqs: {
		categoryId1: [],
		categoryId2: [],
		categoryId3: []
	},
	error: null,
	loading: false
};

export const getFaqsThunk: any = createAsyncThunk('main/getFAQs', async (_, {rejectWithValue}) => {
	try {
		const data = await SERVICES.FAQS.getFAQs();
		return data;
	} catch (err) {
		return rejectWithValue(err);
	}
});

const faqReducer = createSlice({
	name: 'faq',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getFaqsThunk.pending, state => {
			state.loading = true; // Set loading to true when request starts
			state.error = null; // Clear previous errors
		});
		builder.addCase(getFaqsThunk.fulfilled, (state, action) => {
			const categorizedFaqs = action.payload.data.items
				// Sorting by order number from data
				.sort((a: any, b: any) => a.sequence - b.sequence)
				.reduce(
					(acc: any, elem: any) => {
						if (elem.categoryId === 1) {
							acc.categoryId1.push(elem);
						} else if (elem.categoryId === 2) {
							acc.categoryId2.push(elem);
						} else if (elem.categoryId === 3) {
							acc.categoryId3.push(elem);
						}
						return acc;
					},
					{
						categoryId1: [],
						categoryId2: [],
						categoryId3: []
					}
				);

			state.faqs = categorizedFaqs;
			state.loading = false; // Set loading to false when request is fulfilled
		});
		builder.addCase(getFaqsThunk.rejected, (state: any, action) => {
			state.loading = false; // Set loading to false when request fails
			state.error = action.payload || action.error.message; // Set the error state
		});
	}
});

export default faqReducer.reducer;
