import {take} from 'lodash';
import API from '../api';
import axiosClient from '../utils/axiosClient';

const getUserTransactions = async (data: any) => {
	const res = await axiosClient.get(API.USERTRANSACTIONS.userTransactions, {
		params: {...data.filter, page: data.page, userId: data.userId, typeIds: [1320], take: 5}
	});

	return res?.data;
};

const USERTRANSACTIONS = {
	getUserTransactions
};

export default USERTRANSACTIONS;
