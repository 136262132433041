import {createSlice} from '@reduxjs/toolkit';
import {Transfer} from '../utils/types';

const initialState: Transfer[] = [
	{
		id: 1,
		statusID: 1,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '02.06.2014 -',
		timeSegmentY: '02.12.2024',
		totalAmount: 250.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	},
	{
		id: 2,
		statusID: 1,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '04.07.2024 -',
		timeSegmentY: '04.11.2024',
		totalAmount: 200.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	},
	{
		id: 3,
		statusID: 2,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '01.01.2024-',
		timeSegmentY: '05.06.2024',
		totalAmount: 300.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	},
	{
		id: 4,
		statusID: 1,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '02.06.2014 -',
		timeSegmentY: '02.12.2024',
		totalAmount: 250.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	},
	{
		id: 5,
		statusID: 1,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '04.07.2024 -',
		timeSegmentY: '04.11.2024',
		totalAmount: 200.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	},
	{
		id: 6,
		statusID: 2,
		number: 111111,
		createdAt_hy: '13 հունվարի 2024, 15:00',
		createdAt_en: ' 13 January 2024, 15:00',
		timeSegment: '01.01.2024-',
		timeSegmentY: '05.06.2024',
		totalAmount: 300.0,
		notification_hy: 'Ապրիլ ամսվա փոխանցում',
		notification_en: 'Postponement of the month of April'
	}
];

const transfersHistoryReducer = createSlice({
	name: 'transfersHistory',
	initialState,
	reducers: {}
});

export default transfersHistoryReducer.reducer;
