import {createSlice} from '@reduxjs/toolkit';
import {INotification} from '../utils/types';

const initialState: INotification[] = [
	{
		id: '1',
		title: 'pages.account.notificationss.title',
		title_hy: 'pages.account.notificationss.title',
		title_en: 'pages.account.notificationss.title',
		description: 'pages.account.notificationss.text',
		description_hy:
			'Լորեմ Իպսումը տպագրության և տպագրական արդյունաբերության համար նախատեսված մոդելային տեքստ է Լորեմ Իպսումը տպագրության և տպագրական արդյունաբերության համար նախատեսված մոդելային տեքստ է',
		description_en:
			'Lorem Ipsum is the industry’s standard dummy text of the printing and typesetting industry Lorem Ipsum is the industry’s standard dummy text of the printing and typesetting industry',
		isRead: false,
	},
	
];

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {}
});

export default notificationsSlice.reducer;
