import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';

const initialState = {
	data: {
		news: [],
		byID: undefined
	},
	loading: false,
	error: null
};

// Thunk for getting all news
export const getNewsThunk: any = createAsyncThunk('main/news', async (state: any, {rejectWithValue}) => {
	try {
		const response = await SERVICES.NEWS.getNews({...state});
		return response.data; // Assuming response.data contains the items directly
	} catch (err: any) {
		return rejectWithValue(err.message || 'Something went wrong');
	}
});

// Thunk for getting news by ID
export const getNewsByIDThunk: any = createAsyncThunk(
	'main/news/id',
	async (state: any, {rejectWithValue}) => {
		const {id, ...all} = state;
		try {
			const response = await SERVICES.NEWS.getNewsByID(id, all);
			return response.data; // Assuming response.data contains the specific news item
		} catch (err: any) {
			return rejectWithValue(err.message || 'Something went wrong');
		}
	}
);

const newsSlice = createSlice({
	name: 'news',
	initialState,
	reducers: {},
	extraReducers: builder => {
		// Handle getNewsThunk
		builder
			.addCase(getNewsThunk.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getNewsThunk.fulfilled, (state, action) => {
				state.data.news = action.payload; // Directly set the response data
				state.loading = false;
			})
			.addCase(getNewsThunk.rejected, (state, action) => {
				state.error = action.payload; // Set the error message
				state.loading = false;
			});

		// Handle getNewsByIDThunk
		builder
			.addCase(getNewsByIDThunk.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getNewsByIDThunk.fulfilled, (state, action) => {
				state.data.byID = action.payload; // Directly set the response data
				state.loading = false;
			})
			.addCase(getNewsByIDThunk.rejected, (state, action) => {
				state.error = action.payload; // Set the error message
				state.loading = false;
			});
	}
});

export default newsSlice.reducer;
