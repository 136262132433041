import API from '../api';
import axiosClient from '../utils/axiosClient';

const getPartnerTransports = async (data: any) => {
	const res = await axiosClient.get(API.PARTNERTRANSPORTS.partnerTransports, {
		params: {...data.filter, page: data.page, take: 2}
	});
	return res?.data;
};

const PARTNERTRANSPORTS = {
	getPartnerTransports
};

export default PARTNERTRANSPORTS;
