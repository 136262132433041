import {createSlice} from '@reduxjs/toolkit';

const loadingSlice = createSlice({
	name: 'loading',
	initialState: {current: true, action: null},
	reducers: {
		setLoading(state, action) {
			state.current = action.payload.current;
		}
	}
});

export const {setLoading} = loadingSlice.actions;
export default loadingSlice.reducer;
