import facebookLogo from './../../../assets/icons/facebook__green.svg';
import instagramLogo from './../../../assets/icons/instagram__green.svg';
import telegramLogo from './../../../assets/icons/telegram__green.svg';
import {Divider, Layout} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import packageJSON from '../../../../package.json';
import imagesflg from '../../../assets/icons/image 9.png';
import bottomLogo from './../../../assets/icons/ABC 1.png';
import mailLogo from './../../../assets/icons/mail__footer.svg';
import logo from './../../../assets/icons/mainLogo (2).svg';
import phoneLogo from './../../../assets/icons/phone__footer.svg';
import './footer.less';
const {Footer, Content} = Layout;

const AppFooter = ({contact}: any) => {
	const {t} = useTranslation();
	const isMobile = useMediaQuery({maxWidth: 700});
	let footerItems = {
		home: t('footer.menu.home'),
		schedule: t('header.menu.scheduleMenu.marches'),
		tickets: t('footer.menu.tickets'),
		FAQ: t('footer.menu.FAQ'),
		about: t('footer.menu.about'),
		news: t('footer.menu.news'),
		privacyPolicy: t('footer.secondMenu.privacyPolicy'),
		termsConditions: t('footer.secondMenu.termsConditions'),
		footer_transport_am: moment().year() + t('footer.secondMenu.footer_transport_am'),
		footer_smartsoft_am: t('footer.secondMenu.footer_smartsoft_am')
	};
	return (
		<Footer className="footer">
			<Content className="footer_content">
				<div className="footer__columns">
					<div className="footer__logo">
						<img src={logo} alt="" />
					</div>
					<div className="footer__links-first">
						<div className="footer__links-items">
							<div className="footer__links-item" key="home">
								<div className="footer__link">
									<Link to={'/'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.home}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="schedule">
								<div className="footer__link">
									<Link to={'/marches'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.schedule}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="tickets">
								<div className="footer__link">
									<Link to={'/tickets'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.tickets}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="FAQ">
								<div className="footer__link">
									<Link to={'/FAQ'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.FAQ}
									</Link>
								</div>
							</div>
						</div>
						<div className="footer__links-items">
							<div className="footer__links-item" key="contact">
								<div className="footer__link">
									<Link to={'/contact'} onClick={() => window.scrollTo(0, 0)}>
										{t('footer.menu.contact')}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="news">
								<div className="footer__link">
									<p>{footerItems.news}</p>
								</div>
							</div>
							<div className="footer__links-items__news">
								<div className="footer__links-item" key="vanadzor">
									<div className="footer__link">
										<Link to={'/news/vanadzor'} onClick={() => window.scrollTo(0, 0)}>
											{t('header.menu.news.vanadzor')}
										</Link>
									</div>
								</div>
								<div className="footer__links-item" key="gyumri">
									<div className="footer__link">
										<Link to={'/news/gyumri'} onClick={() => window.scrollTo(0, 0)}>
											{t('header.menu.news.gyumri')}
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="footer__links-items">
							<div className="footer__links-item" key="about">
								<div className="footer__link">
									<Link to={'/about'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.about}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="privacyPolicy">
								<div className="footer__link">
									<Link to={'/privacyPolicy'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.privacyPolicy}
									</Link>
								</div>
							</div>
							<div className="footer__links-item" key="termsConditions">
								<div className="footer__link">
									<Link to={'/termsAndConditions'} onClick={() => window.scrollTo(0, 0)}>
										{footerItems.termsConditions}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="foother__icon__phone">
						<div className="footer__links-second">
							<div className="footer__links-items">
								<div className="footer__links-item">
									<div className="foother__phone_number">
										{contact.contacts
											.filter((el: any) => el.isActive === true)
											.map((el: any) => {
												return (
													<div key={el.id}>
														<div
															style={{marginBottom: '0'}}
															className="footer__links-item ico">
															<span>
																<img src={phoneLogo} alt="" />
															</span>
															<div className="footer__link">
																<a href={`tel:${el.mainPhone}`}>
																	{el.mainPhone}
																</a>
																{el.secondaryPhone && `, `}
																<a href={`tel:${el.secondaryPhone}`}>
																	{el.secondaryPhone}
																</a>
															</div>
														</div>
														<div className="footer__links-item ico">
															<span>
																<img src={mailLogo} alt="" />
															</span>
															<div className="footer__link">
																<a href="mailto:info@transport.am">
																	{el.email}
																</a>
															</div>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
						</div>
						{contact.contacts
							.filter((el: any) => el.isActive === true)
							.map((elem: any) => {
								return (
									<div className="footer__links-item social" key={elem.id}>
										{elem.facebookLink ? (
											<div className="footer__link-social">
												<a
													href={
														(elem.facebookLink.startsWith('https') ? '' : '//') +
														elem.facebookLink
													}
													target="_blank"
													rel="noreferrer">
													<img src={facebookLogo} alt="facebook" />
												</a>
											</div>
										) : null}
										{elem.telegramLink ? (
											<div className="footer__link-social">
												<a
													href={
														(elem.telegramLink.startsWith('https') ? '' : '//') +
														elem.telegramLink
													}
													target="_blank"
													rel="noreferrer">
													<img src={telegramLogo} alt="telegram" />
												</a>
											</div>
										) : null}

										{elem.instagramLink ? (
											<div className="footer__link-social">
												<a
													href={
														(elem.instagramLink.startsWith('https') ? '' : '//') +
														elem.instagramLink
													}
													target="_blank"
													rel="noreferrer">
													<img src={instagramLogo} alt="instagram" />
												</a>
											</div>
										) : null}
									</div>
								);
							})}
					</div>
				</div>

				<Divider
					style={{background: '#121212', marginBottom: 10, marginTop: 20}}
					className="footer_divider"
				/>
				<div className="footer_transport_bottom">
					<div className="footer_transport_bottom-columns">
						<div className="footer_transport_bottom-column">
							<div className="other-info-footer">
								{isMobile && (
									<div className="footer_transport_bottom-column">
										<div className="footer_transport_images_box">
											<div
												className="footer_transport_bottom-logo"
												style={{marginRight: 20}}>
												<img src={imagesflg} alt="" />
											</div>
											<div className="footer_transport_bottom-logo">
												<img src={bottomLogo} alt="" />
											</div>
										</div>
										<div className="footer_transport_paragraph">
											<p>{t('footer.description')}</p>
											<p>{t('footer.financedBy')}</p>
										</div>
										<div className="div-footer">
											<div className="footer_smartsoft_am_box">
												<div className="footer_transport_am">
													{t('footer.secondMenu.footer_smartsoft_am')}
													<span>
														<Link
															className="footer_smartsoft_am"
															to="https://smartsoft.am"
															target="_blank">
															{t('footer.secondMenu.SMARTSOFT')}
														</Link>
													</span>
													<p className="footer_transport_am">
														{t('footer.secondMenu.footer_smartsoft_am1')}
													</p>
												</div>
											</div>
											<div className="footer_transport_version">
												<p className="footer_transport_am">
													{footerItems.footer_transport_am}
												</p>
												<p className="footer_version">v {packageJSON.version}</p>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						{/*  */}
						{!isMobile && (
							<div>
								<div className="footer_transport_images_box-row">
									<div className="footer_transport_images_box">
										<div
											className="footer_transport_bottom-logo"
											style={{marginRight: 20}}>
											<img src={imagesflg} alt="" />
										</div>
										<div className="footer_transport_bottom-logo">
											<img src={bottomLogo} alt="" />
										</div>
									</div>
									<div className="footer_transport_paragraph">
										<p>{t('footer.description')}</p>
										<p>{t('footer.financedBy')}</p>
										<p style={{color: '#909090'}}>
											{t('footer.secondMenu.footer_smartsoft_am')}
											<Link
												className="footer_smartsoft_am"
												to="https://smartsoft.am"
												target="_blank">
												{t('footer.secondMenu.SMARTSOFT')}
											</Link>
											{t('footer.secondMenu.footer_smartsoft_am1')}
										</p>
										<div className="footer_transport_version">
											<p className="footer_transport_am">
												{footerItems.footer_transport_am}
											</p>
											<p className="footer_version">v {packageJSON.version}</p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</Content>
		</Footer>
	);
};

export default AppFooter;
