import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';

const initialState = {
	data: {
		mainBranch: [],
		sellBranches: [],
		contacts: []
	},
	formErrors: {}
};

export const getContacts = createAsyncThunk('main/getContacts', async (state, {rejectWithValue}) => {
	try {
		const response = await SERVICES.CONTACTS.getContacts(state);
		if (response && response.data && response.data.items) {
			return response.data.items;
		} else {
			throw new Error('Invalid response structure');
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

const contactsSlice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder.addCase(getContacts.fulfilled, (state, action) => {
			const categorizedContacts = action.payload
				.reduce(
					(acc: any, elem: any) => {
						if (elem.typeId === 1) {
							acc.mainBranch.push(elem);
						} else if (elem.typeId === 2) {
							acc.sellBranches.push(elem);
						} else if (elem.typeId === 3) {
							acc.contacts.push(elem);
						}
						return acc;
					},
					{
						mainBranch: [],
						sellBranches: [],
						contacts: []
					}
				);
			state.data = categorizedContacts;
		});
		builder.addCase(getContacts.rejected, (state, action: any) => {
			state.formErrors = t(`messages.code.${action.payload.code}`);
		});
	}
});

export default contactsSlice.reducer;
