import API from '../api';
import axiosClient from '../utils/axiosClient';

const routesPublic = async (params: any) => {
	const res = await axiosClient.get(API.ROUTESPUBLIC.routesPublic, {params});
	return res?.data;
};

const ROUTESPUBLIC = {
	routesPublic
};

export default ROUTESPUBLIC;
