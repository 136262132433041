import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';
import helperFuncs from '../utils/heplerFuncs/functions';

const initialState = {
	data: [],
	formErrors: {}
};

export const getPartnerTransports: any = createAsyncThunk(
	'getPartnerTransports',
	async (state: any, {rejectWithValue}) => {
		let data;
		try {
			const filter = helperFuncs.mutatedateRangeKey(state.filter, [
				'yellowLicensePlateDeadline',
				'yellowLicensePlateReceiptDate'
			]);
			data = await SERVICES.PARTNERTRANSPORTS.getPartnerTransports({...state, filter});
		} catch (err) {
			return rejectWithValue(err);
		}
		return data;
	}
);

const partnersTransportSlice = createSlice({
	name: 'partner/Transports',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getPartnerTransports.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getPartnerTransports.rejected, (state, action: any) => {
				state.formErrors = t(`messages.code.${action.payload.code}`);
			});
	}
});
export const {cleanFormErrors} = partnersTransportSlice.actions;

export default partnersTransportSlice.reducer;
