import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';

let initialState = {
	banner: [],
	loading: false,
	error: null
};

export const getBannerThunk: any = createAsyncThunk(
	'main/getBanner',
	async (state: any, {rejectWithValue}) => {
		let data;
		try {
			data = await SERVICES.BANNER.getBanner();
		} catch (err) {
			return rejectWithValue(err);
		}
		return data;
	}
);

const bannerReducer = createSlice({
	name: 'banner',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getBannerThunk.pending, (state, action) => {
			state.loading = true;
			state.error = null;
		});
		builder
			.addCase(getBannerThunk.fulfilled, (state, action) => {
				state.banner = action.payload.data.items;
				state.loading = false;
			})
			.addCase(getBannerThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload || action.error.message;
			});
	}
});

export default bannerReducer.reducer;
