import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import helperFuncs from '../utils/heplerFuncs/functions';
import {t} from 'i18next';

const initialState = {
	data: {
		items: []
	},
	formErrors: {},
	addNewHolder: false
};

export const getHoldersThunk = createAsyncThunk(
	'ticketHolders/getHolders',
	async (state: any, {rejectWithValue}) => {
		try {
			const filter = helperFuncs.mutatedateRangeKey(state.filter);
			const data = await SERVICES.HOLDERS.getUserHolders({...state, filter});
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const getHolderByIDThunk = createAsyncThunk(
	'ticketHolders/getHoldersId',
	async (state: any, {rejectWithValue}) => {
		try {
			const response = await SERVICES.HOLDERS.getUserHoldersId(state);
			return response.data;
		} catch (err: any) {
			return rejectWithValue(err.message || 'Failed to fetch holder');
		}
	}
);

export const changHolderStatusThunk = createAsyncThunk(
	'ticketHolders/changHolderStatusThunk',
	async (state: any, {rejectWithValue}) => {
		let data: any;
		try {
			const {ticketId, statusId} = state;
			data = await SERVICES.HOLDERS.updateHolderStatus(ticketId, statusId);
		} catch (err: any) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

export const addNewHolderThunk = createAsyncThunk(
	'ticketHolders/addNewHolderThunk',
	async (number: any, {rejectWithValue}) => {
		let data: any;
		try {
			let trimmedNumber = number.replace(/-/g, '');
			data = await SERVICES.HOLDERS.attachHolderToUser({number: trimmedNumber});
		} catch (err: any) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

const accountTicketHolders = createSlice({
	name: 'ticketHolders',
	initialState,
	reducers: {
		resetErrors: state => {
			state.formErrors = {};
		},
		addNewHolderTrigger: state => {
			state.addNewHolder = !state.addNewHolder;
		}
	},
	extraReducers: builder => {
		builder.addCase(getHoldersThunk.fulfilled, (state, action) => {
			state.data.items = action.payload.data;
		});
		builder.addCase(changHolderStatusThunk.rejected, (state, action: any) => {
			state.formErrors = t(`messages.code.${action.payload.code}`);
		});
		builder.addCase(addNewHolderThunk.fulfilled, (state, action) => {
			// state.data.items = action.payload.data;
			state.formErrors = {};
		});
		builder.addCase(addNewHolderThunk.rejected, (state, action: any) => {
			state.formErrors = {number: t('pages.account.ticketHoldersPage.filter.alreadyAttached')};
		});
	}
});
export const {resetErrors, addNewHolderTrigger} = accountTicketHolders.actions;
export default accountTicketHolders.reducer;
