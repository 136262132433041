import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const mockWorkingTicketsData = [
	{
		id: 1,
		creatorId: 1,
		planId: 50,
		typeId: 1,
		holderTypeId: 1,
		qrcode: '{"uid":"818263000000B4","ticket_data":"EOEfbyYNA1mk3aToLAAAAGaTdpNma+mTAAAAAAAAAAAAAAABAAAAAAAAAAAEpNmq","value_counter":1}',
		price: 100,
		expirationDate: '2023-05-10T00:00:00.000Z',
		tripsCount: 1,
		validThruDateNumber: null,
		validThruDateUnit: null,
		nameHy: 'Մեկանգամյա տոմս՝ QR-ով',
		nameEn: 'Մեկանգամյա տոմս՝ QR-ով',
		nameRu: null,
		descriptionHy: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionEn: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionRu: null,
		type: {
			id: 1,
			hy: 'Մեկանգամյա օգտագործման տոմս',
			en: 'Single use ticket',
			ru: 'Разовый билет'
		},
		holderType: {
			id: 1,
			hy: 'QR',
			en: 'QR',
			ru: 'QR'
		},
		updatedAt: '2024-06-10T19:29:16.176Z',
		createdAt: '2024-06-04T10:49:57.755Z',
		valid: true
	},
	{
		id: 2,
		creatorId: 1,
		planId: 50,
		typeId: 1,
		holderTypeId: 1,
		qrcode: '{"uid":"818263000000B5","ticket_data":"EOEfbyYNBVmk3bAoLAAAAGaTdsBma+nAAAAAAAAAAAAAAAABAAAAAAAAAADVeBFd","value_counter":1}',
		price: 100,
		expirationDate: '2023-05-10T00:00:00.000Z',
		tripsCount: 1,
		validThruDateNumber: null,
		validThruDateUnit: null,
		nameHy: 'Մեկանգամյա տոմս՝ QR-ով',
		nameEn: 'Մեկանգամյա տոմս՝ QR-ով',
		nameRu: null,
		descriptionHy: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionEn: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionRu: null,
		type: {
			id: 1,
			hy: 'Մեկանգամյա օգտագործման տոմս',
			en: 'Single use ticket',
			ru: 'Разовый билет'
		},
		holderType: {
			id: 1,
			hy: 'QR',
			en: 'QR',
			ru: 'QR'
		},
		updatedAt: '2024-06-10T19:29:16.176Z',
		createdAt: '2024-06-04T10:49:57.755Z',
		valid: true
	},
	{
		id: 3,
		creatorId: 1,
		planId: 50,
		typeId: 1,
		holderTypeId: 1,
		qrcode: '{"uid":"818263000000B6","ticket_data":"EOEfbyYNB1mk3b9oLAAAAGaTdv5ma+n+AAAAAAAAAAAAAAABAAAAAAAAAABoRv/p","value_counter":1}',
		price: 100,
		expirationDate: '2023-05-10T00:00:00.000Z',
		tripsCount: 1,
		validThruDateNumber: null,
		validThruDateUnit: null,
		nameHy: 'Մեկանգամյա տոմս՝ QR-ով',
		nameEn: 'Մեկանգամյա տոմս՝ QR-ով',
		nameRu: null,
		descriptionHy: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionEn: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionRu: null,
		type: {
			id: 1,
			hy: 'Մեկանգամյա օգտագործման տոմս',
			en: 'Single use ticket',
			ru: 'Разовый билет'
		},
		holderType: {
			id: 1,
			hy: 'QR',
			en: 'QR',
			ru: 'QR'
		},
		updatedAt: '2024-06-10T19:29:16.176Z',
		createdAt: '2024-06-04T10:49:57.755Z',
		valid: false
	},
	{
		id: 4,
		creatorId: 1,
		planId: 50,
		typeId: 1,
		holderTypeId: 1,
		qrcode: '{"uid":"818263000000B7","ticket_data":"EOEfbyYNC9mk3cmoLAAAAGaTdydma+onAAAAAAAAAAAAAAABAAAAAAAAAADJCSd+","value_counter":1}',
		price: 100,
		expirationDate: '2023-05-10T00:00:00.000Z',
		tripsCount: 1,
		validThruDateNumber: null,
		validThruDateUnit: null,
		nameHy: 'Մեկանգամյա տոմս՝ QR-ով',
		nameEn: 'Մեկանգամյա տոմս՝ QR-ով',
		nameRu: null,
		descriptionHy: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionEn: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionRu: null,
		type: {
			id: 1,
			hy: 'Մեկանգամյա օգտագործման տոմս',
			en: 'Single use ticket',
			ru: 'Разовый билет'
		},
		holderType: {
			id: 1,
			hy: 'QR',
			en: 'QR',
			ru: 'QR'
		},
		updatedAt: '2024-06-10T19:29:16.176Z',
		createdAt: '2024-06-04T10:49:57.755Z',
		valid: true
	},
	{
		id: 5,
		creatorId: 1,
		planId: 50,
		typeId: 1,
		holderTypeId: 1,
		qrcode: '{"uid":"818263000000B8","ticket_data":"EOEfbyYNDdmk3dFoLAAAAGaTd0Vma+pFAAAAAAAAAAAAAAABAAAAAAAAAACIfstu","value_counter":1}',
		price: 100,
		expirationDate: '2023-05-10T00:00:00.000Z',
		tripsCount: 1,
		validThruDateNumber: null,
		validThruDateUnit: null,
		nameHy: 'Մեկանգամյա տոմս՝ QR-ով',
		nameEn: 'Մեկանգամյա տոմս՝ QR-ով',
		nameRu: null,
		descriptionHy: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionEn: 'Մեկանգամյա տոմս՝ QR-ով',
		descriptionRu: null,
		type: {
			id: 1,
			hy: 'Մեկանգամյա օգտագործման տոմս',
			en: 'Single use ticket',
			ru: 'Разовый билет'
		},
		holderType: {
			id: 1,
			hy: 'QR',
			en: 'QR',
			ru: 'QR'
		},
		updatedAt: '2024-06-10T19:29:16.176Z',
		createdAt: '2024-06-04T10:49:57.755Z',
		valid: true
	}
];

let initialState = {
	myTickets: []
};

export const getMyTicketsThunk: any = createAsyncThunk(
	'user/tickets', // YANI, SIG ISKAKAN CHE
	async (state: any, {rejectWithValue}) => {
		try {
			return await new Promise<any>(res => {
				setTimeout(() => {
					res({data: {items: mockWorkingTicketsData}});
				}, 1000);
			});
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

const userTicketsReducer = createSlice({
	name: 'userTickets',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getMyTicketsThunk.fulfilled, (state, action) => {
			state.myTickets = action.payload.data.items;
		});
	}
});

export default userTicketsReducer.reducer;
