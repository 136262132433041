import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import {Suspense} from 'react';
import 'moment/locale/hy-am';
import 'moment/locale/en-gb';
import moment from 'moment';
import FullScreenLoading from '../src/Components/FullScreenLoading';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/hy-am';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ru';
import 'dayjs/locale/fr';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// moment.updateLocale('hy-am', {
// 	week: {
// 		dow: 1
// 	}
// });

// moment.updateLocale('en-gb', {
// 	week: {
// 		dow: 1
// 	}
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Suspense fallback={<FullScreenLoading />}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
