import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import helperFuncs from '../utils/heplerFuncs/functions';
import {t} from 'i18next';

const initialState = {
	data: [],
	formErrors: {}
};

export const getUserTransactions = createAsyncThunk(
	'main/getUserTransactions',
	async (state: any, {rejectWithValue}) => {
		try {
			const filter = helperFuncs.mutatedateRangeKey(state.filter, ['transactionDate']);
			const data = await SERVICES.USERTRANSACTIONS.getUserTransactions({...state, filter});
			return data;
		} catch (err: any) {
			// Ensure that error object has a `code` property for proper translation
			return rejectWithValue({code: err.response?.status, message: err.message});
		}
	}
);

const historyMarchesReducer = createSlice({
	name: 'account/historyMarches',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserTransactions.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getUserTransactions.rejected, (state, action: any) => {
				state.formErrors = t(`messages.code.${action.payload.code}`);
			});
	}
});

export default historyMarchesReducer.reducer;
