import {createSlice} from '@reduxjs/toolkit';
import {IProvidedServices} from '../utils/types';

const initialState: IProvidedServices[] = [
	{
		id: '1',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	},
	{
		id: '2',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	},
	{
		id: '3',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	},
	{
		id: '4',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	},
	{
		id: '5',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	},
	{
		id: '6',
		sessionStart_hy: '13 հունվարի 2024, 15:00',
		sessionStart_en: '13 January 2024, 15:00',
		sessionStartPlace_hy: 'Շիրակի միջազգային օդանավակայան',
		sessionStartPlace_en: 'Shirak International Airport',
		sessionEnd: '15:30',
		sessionEndPlace_en: 'Paruyr Sevak street',
		sessionEndPlace_hy: 'Պարույր Սևակի փողոց',
		driverName_en: 'Armen Harutyunyan',
		driverName_hy: 'Արմեն Հարությունյան',
		city_en: 'Gyumri',
		city_hy: 'Գյումրի',
		passengersCount: '200',
		paymentAmount: '100.000',
		transactionsAmount: '200.000',
		createdAt: '11.11.2023'
	}
];

const providedServicesSlice = createSlice({
	name: 'providedServices',
	initialState,
	reducers: {}
});

export default providedServicesSlice.reducer;
