import API from '../api';
import axiosClient from '../utils/axiosClient';
import {ISignIn, ISignUp} from './types';

const signIn = async (data: ISignIn) => {
	const res = await axiosClient.post(API.AUTH.signIn, data);
	return res?.data;
};

const signUp = async (data: ISignUp) => {
	const res = await axiosClient.post(API.AUTH.signUp, data);
	return res;
};

const signOut = async () => {
	const res = await axiosClient.post(API.AUTH.signOut);
	return res;
};

const AUTH = {
	signIn,
	signUp,
	signOut
};

export default AUTH;
