import API from '../api';
import axiosClient from '../utils/axiosClient';

const getBusStop = async (data: any) => {
	const res = await axiosClient.get(API.BUSSTOPS.busStops, {params: data});
	return res?.data;
};
const getBusStopById = async (busStopId: number) => {
	const res = await axiosClient.get(API.BUSSTOPS.busStops + '/' + busStopId, {});
	return res?.data;
};
const BUS_STOPS = {
	getBusStopById,
	getBusStop
};

export default BUS_STOPS;
