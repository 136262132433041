import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';

const initialState = {
	partners: [],
	formErrors: {}
};

export const updatePartnerInfoThunkPass = createAsyncThunk(
	'partners/changePartnerPassword',
	async (args: {currentPassword: any; password: any; rePassword: any}, {rejectWithValue}) => {
		try {
			const data = await SERVICES.PARTNER.changePartnerPass(args);
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

const partners = createSlice({
	name: 'account/partners',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(updatePartnerInfoThunkPass.fulfilled, (state, action) => {
				state.partners = action.payload.data;
			})
			.addCase(updatePartnerInfoThunkPass.rejected, (state, action: any) => {
				if (action?.payload?.message?.currentPassword === 'Invalid current password.') {
					state.formErrors = {
						currentPassword: t(`messages.code.${action.payload.code}`)
					};
				}
				
			});
	}
});

export const {cleanFormErrors} = partners.actions;
export default partners.reducer;
