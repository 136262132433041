import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';

// Extended initialState with loading and error properties
let initialState = {
	tickets: [],
	loading: false,
	error: null
};

// Async thunk for fetching tickets
export const getTicketsThunk: any = createAsyncThunk(
	'main/getTickets',
	async (state: any, {rejectWithValue}) => {
		try {
			const response = await SERVICES.TICKETS.getTickets();
			return response; // Assuming the response contains the data you need
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

// Slice with loading and error handling
const passengerTicketsReducer = createSlice({
	name: 'myTickets',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getTicketsThunk.pending, state => {
				state.loading = true;
				state.error = null; // Reset error on new request
			})
			.addCase(getTicketsThunk.fulfilled, (state, action) => {
				state.tickets = action.payload.data.items; // Update tickets with response data
				state.loading = false;
			})
			.addCase(getTicketsThunk.rejected, (state, action) => {
				state.error = action.payload; // Set error message
				state.loading = false;
			});
	}
});

export default passengerTicketsReducer.reducer;
