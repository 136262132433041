import {createSlice} from '@reduxjs/toolkit';

const initialState: any[] = [
	{
		id: 465,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 463253321565,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 346346465,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 41231265,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 465457457,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 423423465,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 44576687565,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 423423465,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 465464575,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 4668758775,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	},
	{
		id: 4636685675,
		name_hy: 'Արմեն Հարությունյան Հարությունի',
		name_en: 'Armen Harutyunyan Harutyun',
		driversLicenseIssueDate: '01.01.1995',
		driversLicenseEndDate: '01.01.2024',
		workContractNum: 'N 36',
		phone: '077111111',
		march_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		march_en: 'Shirak international airport - Paruyr Sevak str.'
	}
];

const partnerDrivers = createSlice({
	name: 'partnerDrivers',
	initialState,
	reducers: {}
});

export default partnerDrivers.reducer;
