import API from '../api';
import axiosClient from '../utils/axiosClient';
import {formData} from '../utils/heplerFuncs/formData';

const getUser = async () => {
	const res = await axiosClient.get(API.USER.user);
	return res?.data;
};

const updateUser = async (data: any, id: any) => {
	const res = await axiosClient.patch(API.USER.updateUser + '/' + id, data);
	return res?.data;
};

const updateImage = async (data: any, id: any) => {
	const res = await axiosClient.post(API.USER.userTemporaryApi + '/' + id + '/image', formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return res?.data;
};

const deleteUserImage = async (id: any) => {
	const res = await axiosClient.delete(API.USER.userTemporaryApi + '/' + id + '/image');
	return res?.data;
};

const changeUserPass = async (data: any) => {
	const res = await axiosClient.patch(API.USER.updatePassword, data);

	return res?.data;
};

const changeUserIcon = async () => {
	const res = await axiosClient.patch(API.USER.user);
	return res?.data;
};

const USER = {
	updateImage,
	getUser,
	changeUserPass,
	updateUser,
	deleteUserImage
};

export default USER;
