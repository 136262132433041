import {ConfigProvider, Layout} from 'antd';
import en from 'antd/es/locale/en_GB';
import hy from 'antd/lib/locale/hy_AM';
import React, {Suspense, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation} from 'react-router-dom';
import FullScreenLoading from '../src/Components/FullScreenLoading';
import './App.less';
import {PrivateRoute} from './Components/PrivateRoute';
import Footer from './Components/layout/Footer';
import Header from './Components/layout/Header';
import {IRouteProps} from './utils/types';

import loader from './assets/loader/loadinggif.gif';
import Account from './pages/Account';
import {setLoading} from './store/loading.slice';
import {getUserThunk} from './store/auth.slice';
import {AppDispatch} from './store';
import {getContacts} from './store/contacts.slice';
import {useMediaQuery} from 'react-responsive';

const langs: any = {
	hy,
	en
};

const {Content} = Layout;

function App() {
	const availableRoutesList = useSelector(({routes}) => routes.data);
	const auth = useSelector(({auth}) => auth.data);
	const loading = useSelector(({loading}) => loading);
	const dispatch = useDispatch<AppDispatch>();
	const {i18n} = useTranslation();
	const {pathname} = useLocation();
	const contact = useSelector((state: any) => state.contacts.data);

	useEffect(() => {
		// FEATURE
		// disable scrolling to top when in account page (Just uncomment it)
		// if (!pathname.startsWith('/account')) {
		window.scrollTo(0, 0);
		// }
	}, [pathname]);

	useEffect(() => {
		// dispatch(getContacts());
		setTimeout(() => {
			// dispatch(setLoading({current: false, action: null}));
		}, 2000);
		localStorage.getItem('token') && dispatch(getUserThunk());
	}, []);
	let {t} = useTranslation();
	const isMobile = useMediaQuery({maxWidth: 480});
	if (loading.current)
		return (
			<div className="transparentLoading" style={{display: 'flex', flexDirection: 'column'}}>
				<p style={{fontSize: isMobile ? 40 : 80, color: '#009e83', fontWeight: '400'}}>{t('soon')}</p>
				<img src={loader} alt="" />
			</div>
		);

	return (
		<ConfigProvider
			locale={langs[i18n.language] || en}
			wave={{disabled: true}}
			theme={{
				components: {
					DatePicker: {
						cellHoverWithRangeBg: 'rgba(0, 158, 131, 0.3)',
						cellRangeBorderColor: '#009E83'
					}
				},

				token: {
					colorPrimary: '#009E83'
				}
			}}>
			<Layout className="layout">
				{/* <Header /> */}
				<Layout style={{minHeight: '100vh'}}>
					<>
						<Suspense fallback={<FullScreenLoading />}>
							<Content className={`content`} style={{flex: 1}}>
								{/* {auth.isAuthenticated &&
								(pathname.startsWith('/account') || pathname.startsWith('/partner')) ? (
									<PrivateRoute
										isAuthenticated={auth?.isAuthenticated}
										isPrivate={true}
										role={
											availableRoutesList.find(
												(el: IRouteProps) =>
													el.path ===
													(auth.roleId === 4 ? '/account' : '/partner/:name')
											).role
										}>
										<Account />
									</PrivateRoute>
								) : (
									<Routes>
										{availableRoutesList.map(
											({path, pagePath, isPrivate, role}: IRouteProps, idx: number) => {
												let Component = React.lazy(() => import(`/pages${pagePath}`));
												return (
													<Route
														path={path}
														key={idx}
														handle={true}
														element={
															<PrivateRoute
																isAuthenticated={auth.isAuthenticated}
																isPrivate={isPrivate}
																role={role}>
																<Component />
															</PrivateRoute>
														}
													/>
												);
											}
										)}
									</Routes>
								)} */}
							</Content>
						</Suspense>
					</>
				</Layout>
				<Footer contact={contact} />
			</Layout>
		</ConfigProvider>
	);
}

export default App;
