import API from '../api';
import axiosClient from '../utils/axiosClient';

const getPartnerTransactions = async (data: any) => {
	const res = await axiosClient.get(API.PARTNERTRANSACTIONS.partnerTransactions, {
		params: {...data.filter, page: data.page, partnerId: data.partnerId}
	});
	return res?.data;
};

const PARTNERTRANSACTIONS = {
	getPartnerTransactions
};

export default PARTNERTRANSACTIONS;
