import {createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import SERVICES from '../services';
import moment from 'moment';
import helperFuncs from '../utils/heplerFuncs/functions';

const initialState: any = {
	data: {
		items: []
	},
	formErrors: {},
	addComplaintModal: false
};

export const getUserComplaints: any = createAsyncThunk(
	'account/complaints',
	async (state: any, {rejectWithValue}) => {
		try {
			const filter = helperFuncs.mutatedateRangeKey(state.filter);
			const data = await SERVICES.COMPLAINTS.getUserComplaints({...state, filter});
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

const accountComplaints = createSlice({
	name: 'complaints',
	initialState,
	reducers: {
		resetErrors: state => {
			state.formErrors = {};
		},
		addComplaintModalTrigger: state => {
			state.addComplaintModal = !state.addComplaintModal;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserComplaints.fulfilled, (state, action) => {
				const newItems = action.payload.data.items.map((elem: any) => ({
					key: `${elem.id}`,
					productName_en: elem.title,
					productName_hy: elem.title,
					createdAt: elem.createdAt,
					statusId: elem.status,
					typeId: elem.type,
					msgs: [
						{
							sender: 0,
							text: elem.description,
							name_hy: elem.customerFirstName,
							name_en: elem.customerFirstName,
							answerAttachedFile: elem.answerAttachedFile,
							attachedFile: elem.attachedFile
						},
						{
							sender: 1,
							text: elem.answer,
							name_hy: 'Օպերատոր',
							name_en: 'Operator',
							answerAttachedFile: elem.answerAttachedFile,
							attachedFile: elem.attachedFile
						}
					],
					group: false
				}));
				state.data = {...action.payload.data, items: newItems};
			})
			.addCase(getUserComplaints.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	}
});
export const {addComplaintModalTrigger, resetErrors} = accountComplaints.actions;
export default accountComplaints.reducer;
