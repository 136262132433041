import API from '../api';
import axiosClient from '../utils/axiosClient';

const getNews = async (data: Record<string, any>) => {
	const res = await axiosClient.get(API.NEWS.news, {params: {page: data.page, ...data}});
	return res?.data;
};
const getNewsByID = async (id: number, data: Record<string, any>) => {
	const res = await axiosClient.get(API.NEWS.newsByID + id, data);
	return res?.data;
};

const NEWS = {
	getNews,
	getNewsByID
};

export default NEWS;
