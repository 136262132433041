import API from '../api';
import axiosClient from '../utils/axiosClient';

const getTickets = async () => {
	const res = await axiosClient.get(API.TICKETS.tickets /*  + `?isPublic=true` */);
	return res?.data;
};

const TICKETS = {
	getTickets
};

export default TICKETS;
