import {createSlice} from '@reduxjs/toolkit';
import {IPaymentHistory} from '../utils/types';

const initialState: IPaymentHistory[] = [
	{
		id: 465,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_hy: '14 հունիս 2024, 15:00:00',
		createdAt_en: '14 June 2024, 15:00:00',
	},
	{
		id: 469,
		status: '',
		productName_hy: 'Հաշվեկշռի համալրում',
		paymentMethod_hy: 'Վճարային տերմինալ',
		paymentMethod_en: 'Balance charge',
		productName_en: 'Terminal',
		amount: 500,
		createdAt_hy: '13 հունիս 2024, 15:50:00',
		createdAt_en: '13 June 2024, 15:50:00',
	},
	{
		id: 46225,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 422165,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 4312365,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 46312341235,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 412312365,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 461231235,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 4631231231235,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 12312465,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 465123123,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 4653123123,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 46123123135,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 123123123465,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 462131231235,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 123123,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 123123123123,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 422222222265,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	},
	{
		id: 4222222221241265,
		status: '',
		paymentMethod_hy: 'Բանկային քարտ',
		productName_hy: 'Մեկանգամյա  տոմս',
		paymentMethod_en: 'Bank Card',
		productName_en: 'One Use',
		amount: 500,
		createdAt_en: '13 January 2024, 15:00:00',
		createdAt_hy: '13 հունվարի 2024, 15:00:00'
	}
];

const passengerPaymentHistory = createSlice({
	name: 'paymentHistory',
	initialState,
	reducers: {}
});

export default passengerPaymentHistory.reducer;
