import API from '../api';
import axiosClient from '../utils/axiosClient';

const getUserHolders = async (data: any) => {
	const res = await axiosClient.get(API.HOLDERS.holders, {
		params: {...data.filter, page: data.page, take: 4, orderBy: 'updatedAt', order: 'DESC'}
	});
	return res?.data;
};

const getUserHoldersId = async (ticketId: any) => {
	const res = await axiosClient.get(API.HOLDERS.holdorsId + '/' + ticketId, {});
	return res?.data;
};

const updateHolderStatus = async (ticketId: any, statusId: number) => {
	const res = await axiosClient.patch(`${API.HOLDERS.holdorsId}/${ticketId}`, {statusId});
	return res?.data;
};
const attachHolderToUser = async (data: any) => {
	const res = await axiosClient.post(API.HOLDERS.holders, data);
	return res?.data;
};

const HOLDERS = {
	getUserHolders,
	getUserHoldersId,
	updateHolderStatus,
	attachHolderToUser
};

export default HOLDERS;
