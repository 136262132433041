import API from '../api';
import axiosClient from '../utils/axiosClient';

const getFAQs = async () => {
	const res = await axiosClient.get(API.FAQS.faqs + '?page=1&take=20');
	return res?.data;
};

const FAQS = {
	getFAQs
};

export default FAQS;
