import API from '../api';
import axiosClient from '../utils/axiosClient';

const getContacts = async (data:any) => {
	const res = await axiosClient.get(API.CONTACTS.contacts, data);
	return res?.data;
};

const CONTACTS = {
	getContacts
};

export default CONTACTS;
