import {createSlice} from '@reduxjs/toolkit';

const initialState: any[] = [
	{
		id: 423523565,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 463123125415,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 4634634665,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 4534534565,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 412312312365,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 461231231235,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 461231235,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 465235235,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 235235465,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 1111465,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 46341231231235,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 4345345165,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 41245124365,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	},
	{
		id: 4312312365,
		marchName_hy: 'Շիրակի միջազգային օդանավակայան - Պարույր Սևակի փողոց',
		marchName_en: 'Shirak International Airport - Paruyr Sevak str.',
		city_hy: 'Գյումրի',
		city_en: 'Gyumri',
		busType_hy: 'Միկրոավտոբուս',
		busType_en: 'Minibus',
		routeAllocationStartDate: '01.01.2024',
		routeAllocationEndDate: '01.01.2024',
		routeAllocationCode: '012345678'
	}
];

const partnerRoutes = createSlice({
	name: 'partnerRoutes',
	initialState,
	reducers: {}
});

export default partnerRoutes.reducer;
