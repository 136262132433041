import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';

const initialState = {
	userInfo: [],
	formErrors: {}
};

export const updateUserInfoThunk = createAsyncThunk(
	'auth/updateUserInfo',
	async (args: [values: any, id: number], {rejectWithValue}) => {
		try {
			const [values, id] = args;
			const data = await SERVICES.USER.updateUser(values, id);
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const updateUserInfoThunkPass = createAsyncThunk(
	'auth/changeUserPassword',
	async (args: {currentPassword: any; password: any; rePassword: any}, {rejectWithValue}) => {
		try {
			const data = await SERVICES.USER.changeUserPass(args);
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

const userInfoSlice = createSlice({
	name: 'account/userInfo',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(updateUserInfoThunk.fulfilled, (state, action) => {
				state.userInfo = action.payload.data;
			})
			.addCase(updateUserInfoThunkPass.fulfilled, (state, action) => {
				state.userInfo = action.payload.data;
			})
			.addCase(updateUserInfoThunkPass.rejected, (state, action: any) => {
				if (action?.payload?.message?.currentPassword === 'Invalid current password.') {
					state.formErrors = {
						currentPassword: t(`messages.code.${action.payload.code}`)
					};
				}
			});
	}
});

export const {cleanFormErrors} = userInfoSlice.actions;
export default userInfoSlice.reducer;
