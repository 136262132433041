import API from '../api';
import axiosClient from '../utils/axiosClient';

const getHoldersNumberBalance = async (number: string) => {
	const res = await axiosClient.get(`${API.HOLDERSNUBERBALANCE.holdersNuberBalance}/${number}/balance`);
	return res?.data;
};

const HOLDERSNUBERBALANCE = {
	getHoldersNumberBalance
};

export default HOLDERSNUBERBALANCE;
