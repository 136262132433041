import API from '../api';
import axiosClient from '../utils/axiosClient';

const changePartnerPass = async (data: any) => {
	const res = await axiosClient.patch(API.PARTNER.partner, data);
	return res?.data;
};

const PARTNER = {
	changePartnerPass
};

export default PARTNER;
