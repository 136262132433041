import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import SERVICES from '../services';

interface RoutesPublicState {
	data: any[];
	formErrors: any;
}

const initialState: RoutesPublicState = {
	data: [],
	formErrors: {}
};

export const getRoutesPublic = createAsyncThunk(
	'main/routesPublic',
	async (params: any, {rejectWithValue}) => {
		try {
			const data = await SERVICES.ROUTESPUBLIC.routesPublic(params);
			return data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

const routesPublic = createSlice({
	name: 'routesPublic',
	initialState,
	reducers: {
		setRoutesByCordinates: (state, action: PayloadAction<{data: any[]}>) => {
			state.data = action.payload.data;
		},
		emptyRoutes: state => {
			state.data = [];
		},
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getRoutesPublic.fulfilled, (state, action: PayloadAction<any>) => {
				state.data = action.payload.data.items;
			})
			.addCase(getRoutesPublic.rejected, (state, action) => {
				state.formErrors = action.payload || 'An error occurred';
			});
	}
});

export const {setRoutesByCordinates, cleanFormErrors, emptyRoutes} = routesPublic.actions;

export default routesPublic.reducer;
